// Base
// ----

// Apply a natural box layout model to everything
*,
*:before,
*:after {
    box-sizing: border-box;
}

// Global
// --------------------------------------------------------
html,
body,
button,
textarea,
input {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

html {
    font-size: 100%;
}

body {
    font-family: $fontSans;
    font-size: (($emBase / 16) * 1em);
    font-weight: $regular;
    letter-spacing: 0.02em;
    line-height: $baseLineHeight;
    color: $grayDark;
    background-color: $offWhite;

    &.modal-open {
        overflow: hidden;
    }
}

// Typography
// --------------------------------------------------------
// Block-level elements
// --------------------------
%proto-heading {
    margin: 0;
}

h1 {
    @extend %proto-heading;
}

h2 {
    @extend %proto-heading;
}

h3 {
    @extend %proto-heading;
}

h4 {
    @extend %proto-heading;
}

h5 {
    @extend %proto-heading;
}

h6 {
    @extend %proto-heading;
}

p {
    margin: 0 0 $verticalSpace;
}

ul,
ol {
    margin: 0 0 $verticalSpace;

    nav & {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
    }
}

hr {
    display: block;
    border: 0;
    border-top: 1px solid $blueLightAlt;
    margin: 0 0 $verticalSpace;
}

blockquote {
    margin: 0 0 $verticalSpace;
}

input,
textarea,
button {
    padding: 0 0.25em;
    border: 1px solid mix($gray, $grayLight);

    &:focus {
        outline: none;
    }
}

figure {
    margin: 0;
}

[tabindex] {
    &:focus {
        outline: none;
    }
}

// Inline elements
// --------------------------
a {
    @include hover-opacity;
    color: inherit;

    .page-header--bar &,
    nav & {
        @include hover-opacity(undo);
        text-decoration: none;
    }
}

b,
strong {
    // font-weight: $bold;
}

small {
    font-size: 1em;
}

// Flickity v2.0.5
// ---------------

.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus {
    outline: none;
}

.flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
}

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

// Arrows
// --------------------------------------------------------
$arrowColor: #e2e6dc;
$arrowOverhang: em(218);

.flickity-prev-next-button {
    @include hover-opacity;
    position: absolute;
    top: 50%;
    border: none;
    border-top: em(38) solid transparent;
    border-bottom: em(38) solid transparent;
    background: transparent;
    cursor: pointer;
    transform: translateY(-50%);

    svg {
        display: none;
    }

    &.previous {
        border-right: em(48) solid $arrowColor;
        right: calc(100% + #{$arrowOverhang});
    }

    &.next {
        border-left: em(48) solid $arrowColor;
        left: calc(100% + #{$arrowOverhang});
    }

    &:disabled {
        opacity: 0.3;
        cursor: auto;
    }
    @include respond($containerWidth) {
        $arrowOverhang: em(35);

        &.previous {
            right: calc(100% + #{$arrowOverhang});
        }

        &.next {
            left: calc(100% + #{$arrowOverhang});
        }
    }
    @include respond($michigan) {
        width: em(30);
        border-top-width: em(24);
        border-bottom-width: em(24);

        &.previous {
            border-right-width: em(30);
            right: auto;
            left: em(-12);
        }

        &.next {
            border-left-width: em(30);
            right: em(-12);
            left: auto;
        }
    }
    @include respond($vostok) {
        position: static;
        margin: 0 em($horizontalSpaceNarrow * 2);
        transform: none;
    }
}

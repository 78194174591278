// Animations
// ----------

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

// Animation when elements enter viewport
.js [data-animate] {
    opacity: 0;
    transform: translateY(50px);
    transition: 0.5s ease-out;
    transition-property: opacity, transform;

    &.is-visible {
        opacity: 1;
        transform: translateY(0);
    }
}

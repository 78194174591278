// Variables
//----------

// Colors
// --------------------------------------------------------
$white: #fff;
$offWhite: #f7f7f8;
$grayLight: #f0f0ef;
$gray: #646464;
$grayDark: #353535;
$black: #000;
$greenLight: #9ec23b;
$green: #006738;
$greenAlt: #39764c;
$turquoise: #00b5a6;
$turquoiseDark: #009e92;
$orange: #f18903;
$red: #f15950;
$yellow: #ffdd00;
$brown: #8e5e25;
$blueLighter: #d1e5ea;
$blueLighterAlt: #e8ecf1;
$blueLight: #31c3e6;
$blueLightAlt: #a6c5cc;
$blue: #1c6d80;
$blueDark: #102b4f;

// Typography
// --------------------------------------------------------
$emBase: 20;
$fontSans: "futura-pt", sans-serif;
$fontSerif: "playfair-display", serif;
$regular: 400;
$bold: 700;

// Measurements
// --------------------------------------------------------
$containerWidth: 1510;
$innerWidth: 1160;
$carouselWidth: 926;
$journalWidth: 992;
$journalInner: 750;
$codaWidth: 728;
$eventsWidth: 564;
$columnNarrow: 287;
$columnWide: ($innerWidth - $columnNarrow);
$journalGutter: (($journalWidth - $journalInner) / 2);
$homepageGutter: 62;
$baseLineHeight: 1.5;
$verticalSpace: 1.25em;
$horizontalSpace: 35;
$horizontalSpaceNarrow: 20;
$duration: 0.2s;
$durationLong: 0.4s;
$timing: ease;

// Responsive breakpoints
// https://en.wikipedia.org/wiki/List_of_lakes_by_area
// --------------------------------------------------------
$superior: 1428;
$victoria: 1330;
$michigan: 1230;
// $baikal: ;
$malawi: 1000;
$erie: 936;
$winnipeg: 860;
$ontario: 820;
$balkhash: 700;
// $ladoga: ;
$vostok: 560;
// $onega: ;

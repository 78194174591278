// Components
// ----------

// Global
// --------------------------------------------------------
.container {
	width: 100%;
	max-width: em($containerWidth);
	margin: 0 auto;
	// Context for hero--header, hero--image
	position: relative;
}

.skip-link {
	display: none;
}

// Hero
// --------------------------
.hero {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	// z-index
	position: relative;
	z-index: 200;
	cursor: pointer;

	.template-home & {
		height: 66.622vw;
		background-size: cover;
		background-position: center center;
	}
	@include respond($containerWidth, min) {
		.template-home & {
			height: em(1006);
		}
	}
	@include respond($malawi) {
		.template-home & {
			height: em(666);
		}
	}
}

.hero--header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: em(42) em(48) 0 em(55);
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	@include respond($malawi) {
		padding: em(12) em(16) 0;
	}
}

.hero--title {
	width: em(521, 50);
	font-size: em(50);
	line-height: 1.1;
	letter-spacing: 0.04em;
	text-transform: lowercase;
	color: $white;
	@include respond($michigan) {
		display: none;
	}
}

.hero--image {
	display: block;
	width: 100%;
	height: auto;
}

.hero--logo,
.hero--logotype {
	display: block;
	opacity: 0.84;
	@include respond($malawi) {
		font-size: 0.622em;
	}
}

.hero--logo {
	width: em(563);
	margin-bottom: em(19);
}

.hero--logotype {
	width: em(443);
}

// Page header
// --------------------------
.page-header {
	width: 100%;
	max-width: em($containerWidth);
	margin: 0 auto;
	top: 0;
	z-index: 300;

	&.is-sticky {
		pointer-events: none;
	}
}

.page-header--bar {
	@include full-bleed($green, none, em($containerWidth - 2));
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: em(8) em(21) em(9);
	pointer-events: auto;
	overflow: hidden;

	@include respond($malawi) {
		padding: em(3) em(16);
	}

	@include respond($vostok) {
		padding-top: em(16);
		padding-bottom: em(17);
	}
}

.page-header--title {
	font-size: 1em;
	height: em(60);

	a,
	img {
		display: block;
		height: 100%;
	}

	@include respond($malawi) {
		height: em(37.25);
	}
}

.page-header--logo {
	width: em(150);

	a,
	img {
		display: block;
		width: 100%;
	}

	@include respond($malawi) {
		a {
			margin-bottom: em(-4);
		}
	}

	@include respond($vostok) {
		display: none;
	}
}

.action-nav {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: em(27);

	.hero & {
		padding: em(8) 0 0;
		margin-left: auto;
	}
	@include respond($malawi) {
		width: em(100);
		padding-right: 0;
	}
}

.action-nav--link {
	@include button;
	margin-left: em(12);

	.page-header--bar & {
		background-color: transparentize($black, 0.83);

		&:hover {
			background-color: transparentize($black, 0.90);
		}
	}
	@include respond($malawi) {
		display: none;
	}
}

.action-nav--toggle {
	@include tripundra($white, em(7), em(42), em(31));
	margin-left: em(19, 17);

	i {
		@include h5($white);
		display: none;
		font-weight: $bold;
		font-style: normal;
		line-height: (31 / 15);
		margin-right: em(20);
		position: absolute;
		top: 0;
		right: 100%;
	}

	.page-header--bar & {
		display: none;
		@include respond($michigan) {
			display: block;
		}
	}
	@include respond($malawi) {
		.hero & {
			text-indent: 0;

			i {
				display: block;
			}
		}
	}
}

.primary-nav {
	background-color: $offWhite;
	padding: em(19) em(21) em(20);
	transition: opacity $duration $timing;

	.is-sticky & {
		opacity: 0;
		visibility: hidden;
	}

	[data-scrolldir=down] .is-sticky & {
		transition: opacity $duration $timing, visibility $duration $duration $timing;
	}

	[data-scrolldir=up] .is-sticky & {
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
	}

	ul {
		display: flex;
		justify-content: space-between;
	}
	@include respond($michigan) {
		display: none;
	}
}

// li
.primary-nav--item {
	@include nav-item;
}

.primary-nav--heading {
}

// Modals
// --------------------------
.modal {
	background-color: transparentize($white, 0.1);
	overflow: hidden;
	position: fixed;
	z-index: 300;
	right: 400em;
	// Show/hide
	transition: opacity $duration $timing, transform 0s $duration $timing;
	opacity: 0;
	transform: translateX(100%);

	&[aria-hidden=false] {
		transition: opacity $duration $timing, transform 0s $timing;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 1;
		transform: translateX(0);
	}

	@include respond($malawi) {
		background-color: $white;
		opacity: 1;
		transition: transform ($duration * 1.2) $timing;

		&[aria-hidden=false] {
			transition: transform ($duration * 1.2) $timing;
		}
	}
}

.modal--toggle {
	width: em(40);
	height: em(40);
	background: transparent;
	padding: 0;
	border: none;
	position: absolute;
	z-index: 100;
	top: em(46);
	right: em(49);

	path {
		fill: $gray;
	}

	.modal-door & {
		path {
			fill: $green;
		}
	}
	@include respond($malawi) {
		top: em(15);
		right: em(17);
	}
}

.modal--content {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	width: 100%;
	height: 100%;
	max-width: em(676);
	margin: 0 auto;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}

// a
.modal--logo {
	display: block;
	width: em(347);
	margin-bottom: em(23);

	img {
		display: block;
		width: 100%;
		height: auto;
	}
	@include respond($malawi) {
		width: em(228);
	}
	@include respond($vostok) {
		width: em(150);
	}
}

.modal--frame {
	display: block;
	width: em(350);
	height: em(550);
	margin: 0 auto;
	border: 0;
	background: white;
	box-shadow: 0 0.25em 2em rgba(black, 0.1);
}

// ul
.modal--list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	@include respond($malawi) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		padding-bottom: em(48);
	}
}

// li
.modal--item {
	@include nav-item;
	margin-bottom: em(60);
	@include respond($malawi) {
		width: em(280);
		margin-bottom: em(8);
	}
	@include respond($vostok) {
		max-width: em(300);

		.title {
			font-size: em(28);
		}

		p {
			font-size: em(12);
		}
	}
}

.modal--form {
	width: 100%;
	max-width: em(350);
}

.modal--input {
	@include form-input;
	// Context for label
	position: relative;

	label {
		line-height: em(51, 15);
		padding-right: em(16, 15);
		margin-bottom: 0;
		position: absolute;
		top: 0;
		right: 100%;
	}
}

.modal--checkbox {
	@include form-checkbox;
}

.modal--button {
	@include form-button;
}

.modal--hint {
	font-size: em(14);
	text-align: center;
}

// Page footer
// --------------------------
.page-footer {
	text-align: center;
	margin-bottom: em(32);
}

.page-footer--bar {
	@include full-bleed(#fbfcfd, down);
	line-height: 1;

	a {
		display: inline-block;
		font-size: em(38);
		font-weight: $bold;
		text-transform: lowercase;
		text-decoration: none;
		color: $blue;
		padding: em(11, 38) 0 em(12, 38);

		&:after {
			content: "+";
			display: inline-block;
			color: $greenLight;
			margin-right: em(5, 38);
			position: relative;
			top: em(-12, 38);
		}
	}
	@include respond($malawi) {
		a {
			font-size: em(24);
		}
	}
}

.page-footer--contact {
	@include full-bleed($white, down);
	font-size: em(25);
	font-weight: $bold;
	color: $greenAlt;
	padding-top: em(27);
	margin-bottom: em(27, 25);

	[itemtype*=PostalAddress] {
		div {
			display: inline-block;
		}
	}

	[itemprop=streetAddress] {
		&:after {
			content: ",";
		}
	}
	// a
	[itemprop=email],
	[itemprop=telephone] {
		display: block;
		text-decoration: none;
	}
	@include respond($malawi) {
		font-size: em(18);
		padding-right: em($horizontalSpaceNarrow, 18);
		padding-left: em($horizontalSpaceNarrow, 18);
	}
}

.page-footer--logo {
	display: inline-block;
	width: em(344);
	margin-bottom: em(2);
	opacity: 0.84;

	a,
	img {
		display: block;
		width: 100%;
	}

	a {
		@include hover-opacity(undo);
	}
	@include respond($malawi) {
		width: em(160);
	}
}

.footer-nav {
	margin-bottom: em(27);

	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		font-size: em(15);
		font-weight: $bold;
		color: #414242;
	}

	li {
		white-space: nowrap;
	}

	li + li {
		&:before {
			content: "";
			margin: 0 em(14, 15);
			border-left: 1px solid #d0d0cf;
		}
	}

	a {
		@include hover-opacity;
	}
	@include respond($malawi) {
		padding: 0 em($horizontalSpaceNarrow);

		li {
			padding: em(3, 15) em(7, 15);
		}

		li + li {
			&:before {
				display: none;
			}
		}
	}
}

.page-footer--meta {
	a {
		font-size: em(16);
		color: $gray;
		text-decoration: none;
	}

	.logo {
		display: inline-block;
		vertical-align: bottom;
	}
}

// Homepage components
// --------------------------------------------------------
.homepage {
}

.homepage--columns {
	display: flex;
	flex-wrap: wrap;
	@include respond($winnipeg) {
		flex-direction: column;
		align-items: center;
	}
}

.homepage--left,
.homepage--right {
	margin-bottom: em(74);
	@include respond($vostok) {
		margin-bottom: em(16);
	}
}

.homepage--left {
	flex: 1 0 0;
	padding: 0 em(53) 0 em($homepageGutter);
	@include respond($malawi) {
		padding: 0 em($horizontalSpaceNarrow) 0 0;
	}
	@include respond($winnipeg) {
		padding-right: 0;
	}
}

.homepage--right {
	$width: 300;
	width: 26%;
	margin: 0 3% 0 4%;
	@include respond($winnipeg) {
		width: 100%;
		max-width: em($width);
	}
}

// Carousel
// --------------------------
.carousel {
	width: 100%;
	max-width: em($carouselWidth);
	margin: 0 auto em(49);
	@include respond($michigan) {
		max-width: em($carouselWidth + 100);
		padding: 0 em(50);
	}
	@include respond($vostok) {
		text-align: center;
		padding: 0;
		margin-bottom: em(36);
	}
}

.carousel--slide {
	display: flex;
	width: 100%;
	text-align: left;
}

// figure
.carousel--image {
	width: em(187);
	padding-top: em(9);

	img {
		display: block;
		width: 100%;
		height: auto;
	}
	@include respond($ontario) {
		display: none;
	}
}

.carousel--text {
	flex: 1;
	padding-left: em(44);

	> p {
		font-size: em(21);
		line-height: 1.667;
		margin-bottom: em(15);
	}
	@include respond($ontario) {
		padding-left: 0;
	}
}

.carousel--heading {
	@include h1;
	margin-bottom: em(9, 58);

	em {
		display: block;
	}

	p {
		margin-bottom: 0;
	}
}

// Agenda
// --------------------------
$agendaSpace: 30;

.agenda-title,
.agenda-link {
	font-size: em(33);
	font-weight: $bold;
	line-height: 1;
	text-transform: lowercase;
	color: $blue;
}

.agenda-title {
	text-align: right;
	padding-right: em($agendaSpace, 33);
	margin-bottom: em(-8, 33);
}

.agenda-footer {
	text-align: right;
	padding-right: em(10);
	margin: em(-20) 0 em(48);
	@include respond($vostok) {
		margin-bottom: em(30);
	}
}

.agenda-link {
	display: inline-block;
	text-decoration: none;

	&:after {
		content: "+";
		display: inline-block;
		font-size: em(21, 33);
		color: $blueLight;
		margin-left: em(8, 21);
		position: relative;
		top: em(-5, 21);
	}
}

.agenda {
	@include offset-box;
	text-align: right;
	padding: 0 em($agendaSpace) em(39) 0;

	ol {
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	a {
		display: block;
		text-decoration: none;
	}

	p {
		margin-bottom: 0;
	}
}

// li
.agenda--item {
	padding-top: em(16);
}

.agenda--date {
	display: block;
	font-size: em(19);
	font-weight: $bold;
	line-height: 2;
	color: $red;
	background-color: $white;
	padding-right: em($agendaSpace);
	margin: 0 em(-$agendaSpace) em(3, 19) 0;
}

.agenda--title {
	font-size: em(17);
	color: $grayDark;
	margin-bottom: em(3, 17);
}

.agenda--meta {
	font-size: em(17);
	font-style: italic;
	color: #101010;
}

// Adjunct nav
// --------------------------
.adjunct-nav {
	width: 100%;
	max-width: em(280, 17);
	margin: 0 auto;
	font-size: em(17);
	text-align: center;
	line-height: 1.235;

	ul {
	}

	li + li {
		margin-top: em(27, 17);
	}

	a {
		@include hover-opacity;
	}

	strong {
		display: block;
		font-size: em(19, 17);
		font-weight: $bold;
		color: $red;
	}
	@include respond($winnipeg) {
		display: none;
	}
}

.adjunct-nav--title {
	font-size: em(33);
	font-weight: $bold;
	text-transform: lowercase;
	color: $turquoise;
	margin-bottom: em(24, 33);
}

// Callout
// --------------------------
$calloutOverhang: 37;
$calloutOffset: em($horizontalSpace + $calloutOverhang);

.callout {
	$innerWidthEm: em($innerWidth);
	@include bleed(right, $innerWidth, 0);
	background-color: $blueLighter;
	padding: 0 em(112) em(43) 0;
	margin-bottom: em(93);
	margin-left: em(-$homepageGutter);
	box-shadow: 0 em(39) 0 0 $offWhite inset;
	// Context for callout--shape, callout--link
	position: relative;
	@include respond($superior) {
		margin-left: calc((((100vw - #{$innerWidthEm}) / 2) - #{$calloutOffset}) * -1);
	}
	@include respond($michigan) {
		margin-left: em($calloutOverhang);
		padding-right: em($horizontalSpace);
	}
	@include respond($malawi) {
		padding-right: em($horizontalSpaceNarrow);
	}
	@include respond($balkhash) {
		margin-left: em(-$horizontalSpaceNarrow);
	}
	@include respond($vostok) {
		margin-bottom: em(52);
		box-shadow: 0 em(25) 0 0 $offWhite inset;
	}
}

.callout--title {
	@include h1;
	line-height: 1;
	padding-left: em(82, 58);
	margin-bottom: em(9, 58);
	@include respond($balkhash) {
		padding-left: rem($horizontalSpaceNarrow);
	}
}

.callout--shape {
	display: block;
	width: em(99);
	height: em(119);
	position: absolute;
	top: em(-44);
	left: em(-58);
	@include respond($malawi) {
		width: em(60);
		height: em(72);
		top: em(-13);
		left: em(-35);
	}
	@include respond($balkhash) {
		display: none;
	}
}

.callout--text {
	@include flush-paragraphs;
	padding: 0 20% 0 em(82);
	margin-bottom: em(13);

	p {
		font-size: em(19);
		line-height: 1.579;
	}
	@include respond($balkhash) {
		padding: 0 0 0 em($horizontalSpaceNarrow);
		margin-bottom: 0;
	}
}

// ul
.callout--list {
	display: flex;
	justify-content: space-between;
	list-style-type: none;
	padding-left: 0;
	margin: 0 0 0 em(-$calloutOverhang);
	@include respond($balkhash) {
		display: none;
	}
}

// li
.callout--item {
	$count: 5;
	$space: em(43);
	width: calc((100% / #{$count}) - (#{$space} * (#{$count} / (#{$count} - 1))));

	& + & {
		margin-left: $space;
	}
	@include respond($michigan) {
		$space: em($horizontalSpaceNarrow);
		width: calc((100% / #{$count}) - (#{$space} * (#{$count} / (#{$count} - 1))));

		& + & {
			margin-left: $space;
		}
	}
	@include respond($winnipeg) {
		$count: 3;
		$space: em($horizontalSpaceNarrow);
		width: calc((100% / #{$count}) - (#{$space} * (#{$count} / (#{$count} - 1))));

		&:nth-child(n + 4) {
			display: none;
		}
	}
}

.callout--image {
	margin-bottom: em(1);

	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

.callout--subheading,
.callout--sub-subheading {
	letter-spacing: 0.04em;
	line-height: 1;
	color: #4d4e4e;
}

.callout--heading {
	font-size: em(26);
	font-weight: $bold;
	margin-bottom: em(1, 26);
}

.callout--subheading {
	font-family: $fontSerif;
	font-size: em(19);
	font-weight: $regular;
}

.callout--link {
	display: block;
	font-size: em(30);
	font-weight: $bold;
	letter-spacing: 0.04em;
	text-transform: lowercase;
	text-decoration: none;
	color: $brown;
	position: absolute;
	right: em(22, 30);
	bottom: em(-19, 30);

	&:after {
		content: "\00a0\2192";
	}
	@include respond($vostok) {
		font-size: em(20);
	}
}

// Checklist
// --------------------------
.checklist {
	@include bleed(left, $innerWidth, 0, padded);
	width: 100%;
	box-sizing: content-box;
	background-color: #f1f0ef;
	padding-bottom: em(56);
	margin-bottom: em(111);
	box-shadow: 0 em(37) 0 0 $offWhite inset;

	ol {
		column-count: 2;
		column-gap: em(140);
		counter-reset: checklist;
		list-style-type: none;
		padding: 0 em(76) 0 em(120);
		margin-bottom: 0;
	}

	li {
		display: flex;
		align-items: flex-start;
		counter-increment: checklist;

		&:before {
			content: counter(checklist)".";
			display: block;
			font-size: em(63);
			font-weight: $bold;
			letter-spacing: -0.06em;
			line-height: 1;
			color: $blue;
			margin-right: em(34, 63);
		}

		&:nth-child(2) {
			&:before {
				color: $greenLight;
			}
		}

		&:nth-child(3) {
			&:before {
				color: $blueLight;
			}
		}

		&:nth-child(4) {
			&:before {
				color: #4d4e4e;
			}
		}

		&:nth-child(6) {
			&:before {
				color: $red;
			}
		}
	}

	li + li {
		margin-top: em(34);
	}

	p {
		font-size: em(22);
		line-height: 1.136;
		letter-spacing: 0;
		padding-top: em(6, 22);
		margin-bottom: 0;
	}

	a {
		font-weight: $bold;
	}
	@include respond($winnipeg) {
		ol {
			column-gap: em(70);
			padding: 0 em($horizontalSpace);
		}
	}
	@include respond($balkhash) {
		ol {
			column-gap: em($horizontalSpace);
			padding: 0;
		}
	}
	@include respond($vostok) {
		margin-bottom: em(62);
		box-shadow: 0 em(28) 0 0 $offWhite inset;

		ol {
			column-count: 1;
		}
	}
}

.checklist--title {
	@include h1;
	text-transform: none;
	text-align: center;
	margin-bottom: em(57, 58);
}

// Coda
// --------------------------
.coda {
	@include flush-paragraphs;
	width: 100%;
	max-width: em(728);
	margin: 0 auto;

	> p {
		font-size: em(21);
		line-height: 1.667;
	}
}

// figure
.coda--image {
	margin: 0 em(-152) em(60);

	img {
		display: block;
		width: 100%;
		height: auto;
	}
	@include respond($michigan) {
		margin-right: em(-100);
		margin-left: em(-100);
	}
	@include respond($malawi) {
		margin-right: em(-50);
		margin-left: em(-50);
	}
	@include respond($winnipeg) {
		margin-right: em(0);
		margin-left: em(0);
	}
}

.coda--heading {
	@include h1;
	letter-spacing: 0;
	margin-bottom: em(13, 58);
}

// Page content
// --------------------------------------------------------
.page-content {
	display: flex;
	width: 100%;
	max-width: em($innerWidth + ($horizontalSpace * 2));
	padding: em(60) em($horizontalSpace) 0;
	margin: 0 auto em(83);

	.template-home & {
		padding-top: em(78);
		margin-bottom: em(46);
	}

	.template-journal & {
		padding-top: em(29);
	}

	.template-events & {
		padding-top: em(33);
		margin-bottom: em(35);
	}
	@include respond($malawi) {
		padding-right: em($horizontalSpaceNarrow);
		padding-left: em($horizontalSpaceNarrow);
	}
	@include respond($winnipeg) {
		flex-direction: column;
		// Context for content-nav
		position: relative;
	}
	@include respond($vostok) {
		.template-home & {
			padding-top: em(39);
		}
	}
}

.page-content--left {
	order: 1;
	width: (($columnNarrow / $innerWidth) * 100%);
	padding-top: em(10);
}

.page-content--right {
	order: 2;
	flex: 1;
	padding: 0 em($horizontalSpace);
	@include respond($michigan) {
		padding-right: 0;
	}
	@include respond($malawi) {
		padding-left: em($horizontalSpaceNarrow);
	}
	@include respond($winnipeg) {
		padding-left: 0;
	}
}

// Content nav
// --------------------------
.content-nav {
	@include offset-box;
	text-align: right;
	padding: em(18) 0 em(16);

	li {
		font-size: em(18);
		text-transform: lowercase;
		line-height: 1.25;
	}

	a {
		display: block;
		padding: 0.2em 0;
	}

	@include respond($winnipeg) {
		@include offset-box(undo);
		text-align: center;
		padding: 0;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;

		li {
			font-size: 1em;
		}
	}
}

.content-nav--toggle {
	display: none;
	width: 100%;
	font-weight: $bold;
	text-transform: lowercase;
	color: $grayDark;
	background-color: #f1f0ef;
	padding: em(8) 0;
	border: none;
	border-bottom: 1px solid #e3e3e3;
	// z-index
	position: relative;
	z-index: 200;

	&:after {
		content: "v";
		display: inline-block;
		margin-left: em(6);
		transform: scaleY(0.8);
	}

	&[aria-expanded=true] {
		&:after {
			position: relative;
			bottom: em(-4);
			transform: scaleY(0.8) rotate(180deg);
		}
	}
	@include respond($winnipeg) {
		display: block;
	}
}

// ul
.content-nav--list {
	@include respond($winnipeg) {
		background-color: #f1f0ef;
		// background-color: $white;
		padding-bottom: em(8);
		border-bottom: 1px solid #e3e3e3;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 100;
		transition: transform $duration $timing;
		transform: translateY(-100%);

		&[aria-hidden=false] {
			transform: translateY(0);
		}
	}
}

// li
.content-nav--home {
	font-weight: $bold;
	padding-right: em(36, 18);
	margin-bottom: em(14, 18);

	a {
		color: $turquoise;
	}
	@include respond($winnipeg) {
		display: none;
	}
}

// li
.content-nav--item {
	font-weight: $bold;
	color: $grayDark;
	padding-right: em(36, 18);

	&:hover,
	&.active {
		background-color: $white;
	}
	@include respond($winnipeg) {
		padding-right: 0;
	}
}

// li
.content-nav--sub-item {
	color: $gray;
	padding-right: em(49, 18);

	&:hover,
	&.active {
		background-color: $white;
	}

	&.active {
		font-weight: $bold;
		color: $grayDark;
	}

	& & {
		font-weight: $regular;
		padding-right: 0;

		&:hover {
			color: $brown;
		}
	}
	@include respond($winnipeg) {
		padding-right: 0;
		margin-top: 0;
	}
}

// Crux
// Entry content
// --------------------------
.crux {
}

.crux--header {
}

// copy block
.crux--content {
}

// Blocks
// --------------------------
.heading-block {
	@include h1;
	padding-top: em(11, 58);

	.homepage--left & {
		font-size: em(44);
		text-transform: none;
		margin-bottom: em(19, 44);
	}

	em {
		color: $turquoise;
	}
}

h2.heading-block {
	.crux & {
		@include h2;
	}
}

.subheading-block {
	@include h3;
}

.sub-subheading-block {
	@include h3($turquoise);
}

.text-block {
	ul {
		list-style-type: none;
		padding-left: em(20);

		li {
			// Context for :before
			position: relative;

			&:before {
				content: "+";
				font-size: em(28);
				font-weight: $bold;
				color: $blueLight;
				line-height: 1;
				margin-right: em(5, 28);
				position: absolute;
				top: 0;
				right: 100%;
			}
		}

		li + li {
			margin-top: em(9);
		}
	}

	.homepage--left & {
		font-size: em(21);
	}
}

// figure
.image-block,
.map-block {
	$horizontalSpaceEm: em($horizontalSpace);
	@include bleed(right, $innerWidth, $horizontalSpace);
	padding-top: em(56);
	margin-bottom: em(41);

	&.double {
		display: flex;
		justify-content: space-between;

		img {
			width: calc(50% - (#{$horizontalSpaceEm} / 4));
		}
	}

	img {
		display: block;
		width: 100%;
		height: auto;
	}

	figcaption {
		font-size: em(18);
		line-height: 1.25;
		color: $gray;
		padding: em(8, 18) em($horizontalSpace, 18) 0 0;
	}

	.post--content & {
		@include bleed(both, $journalInner, 0, unpadded, $journalWidth);
		padding-top: em(25);
		margin-bottom: em(55);
	}

	.homepage--left & {
		@include bleed(left, $innerWidth, $homepageGutter);
		padding-top: em(70);
		margin-bottom: em(14);

		&:first-child {
			padding-top: em(10);
		}
	}
	@include respond($winnipeg) {
		figcaption {
			padding-right: em($horizontalSpaceNarrow, 18);
			padding-left: em($horizontalSpaceNarrow, 18);
		}
	}
	@include respond($vostok) {
		padding-top: em(22);
		margin-bottom: em(36);

		.post--content & {
			padding-top: em(16);
			margin-bottom: em(34);
		}

		.homepage--left & {
			padding-top: em(24);
		}
	}
}

.map-block {
	// Google doesn't return static maps bigger than 640px wide
	max-width: 640px;
}

.divider-block {
	margin: ($verticalSpace * 2) em($horizontalSpace);
}

.quote-block {
	text-align: center;
	padding: 0 em($horizontalSpace);
}

.quote-block--text {
	display: inline-block;
	background-color: $grayLight;
	padding: ($verticalSpace / 3) em($horizontalSpace / 1.618);

	p {
		font-size: em(22);
		margin-bottom: ($verticalSpace / 4);
	}
}

.quote-block--attribution {
	display: block;
	font-size: em(15);
	font-weight: $bold;
	font-style: normal;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: $turquoiseDark;
}

// Form block
// --------------------------
.form-block {
	$space: em($horizontalSpace / 2);
	background-color: $blueLighterAlt;
	padding: 0 em($horizontalSpace) $verticalSpace;
	box-shadow: 0 em(26) 0 0 $offWhite inset;

	fieldset {
		padding: 0;
		border: none;
		margin: 0;
	}

	label {
		color: $gray;
	}

	input,
	textarea {
		width: 100%;
	}

	textarea {
		resize: none;
	}

	select {
		min-width: 10em;
		padding: 0 em(8) 0 em(4);
		margin-bottom: $verticalSpace / 2;
	}

	.form__row {
		margin-bottom: $verticalSpace / 2;
	}

	.form__label {
		label {
			display: block;
		}

		em {
			display: block;
			margin-bottom: $verticalSpace / 2;
			font-size: em(12);
			line-height: 1.25;
		}
	}

	.form__note {
		float: right;
		margin-top: $verticalSpace;
		font-size: em(14);
	}

	.buttons {
		margin: $verticalSpace 2px 0;
	}

	@include respond($winnipeg) {
		padding: 0 em($horizontalSpaceNarrow) $verticalSpace;
		margin: 0 em(-$horizontalSpaceNarrow);
	}
}

.form-block--heading {
	@include h3($blue);
	width: 100%;
	margin-bottom: em(6, 35);
}

.form-block--button,
.form-block .button {
	@include button;
	padding-top: em(8);
	width: auto;
}

.form-block--disclaimer {
	margin-top: $verticalSpace * 2;
	font-size: em(15, 20);

	p:first-child {
		// Hanging asterisk
		text-indent: -0.5em;
	}
}

// Footnotes
// TODO prevent this from overflowing viewport (plugin?)
// --------------------------
// span
.footnote {
	cursor: pointer;
	// Context for footnote--text
	position: relative;

	&:before {
		content: "";
		display: inline-block;
		vertical-align: top;
		width: em(15);
		height: em(15);
		background-image: url('../images/plus.svg');
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.footnote--text {
	display: block;
	width: em(460, 18);
	font-size: em(18);
	line-height: 1.389;
	letter-spacing: 0;
	color: #2b2b2b;
	background-color: $white;
	padding: em(14, 18) em(22, 18);
	border: 1px solid #a6a5a5;
	border-radius: em(13, 18);
	margin-top: em(18, 18);
	cursor: auto;
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: opacity $duration $timing, visibility $duration $duration $timing;

	.footnote:hover & {
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
		transition: opacity $duration $timing;
	}

	&:before,
	&:after {
		content: "";
		position: absolute;
		bottom: 100%;
	}

	&:before {
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		border-bottom: 24px solid #a6a5a5;
		left: calc(50% - 20px);
	}

	&:after {
		border-left: 19px solid transparent;
		border-right: 19px solid transparent;
		border-bottom: 23px solid $white;
		left: calc(50% - 19px);
	}

	a {
		color: $blue;
	}
	@include respond($vostok) {
		width: em(280, 18);
	}
}

// Faculty components
// --------------------------------------------------------
.faculty {
}

.faculty--group {
	@include offset-wrapper();
	text-align: center;
	padding-right: em(41);
	// Context for faculty--elevator
	position: relative;

	& + & {
		margin-top: em(73);
	}
	@include respond($malawi) {
		padding-right: 0;
	}
}

.faculty--heading {
	@include h2;
	text-align: left;
	margin-bottom: em(22, 40);
}

.faculty--item {
	display: flex;
	text-align: left;

	& + & {
		padding-top: em(24);
	}
	@include respond($vostok) {
		flex-direction: column;
	}
}

// figure
.faculty--image {
	width: em(231);
	padding-top: em(4);

	img {
		display: block;
		width: 100%;
		height: auto;
	}
	@include respond($balkhash) {
		width: em(185);
	}
	@include respond($vostok) {
		width: em(231);
		margin-bottom: em(12);
	}
}

.faculty--details {
	flex: 1;
	padding-left: em(16);

	p {
		font-size: em(18);
	}
	@include respond($vostok) {
		padding-left: em(36);
	}
}

.faculty--subheading,
.faculty--sub-subheading {
	letter-spacing: 0.04em;
	line-height: 1;
	color: #4d4e4e;
}

.faculty--subheading {
	font-size: em(26);
	font-weight: $bold;
	margin-bottom: em(8, 26);
}

.faculty--sub-subheading {
	font-family: $fontSerif;
	font-size: em(19);
	font-weight: $regular;
	margin-bottom: em(19, 19);
}

.faculty--elevator {
	display: inline-block;
	font-size: em(23);
	font-weight: $bold;
	text-decoration: none;
	letter-spacing: 0.04em;
	color: $turquoise;
	position: absolute;
	bottom: em(-35, 23);
}

// Events
// --------------------------------------------------------
.events {
	width: 100%;
	max-width: em(1100);
	margin: 0 auto;
}

.events--header {
	text-align: center;
	margin-bottom: em(12);
}

.events--title {
	@include svg-heading;
}

// ul
.events--key {
	text-align: center;
	list-style-type: none;
	padding-left: 0;
	margin-bottom: em(46);

	li {
		display: inline-block;
		font-size: em(17);
		letter-spacing: 0;
		padding: 0 em(6, 17);
	}

	small {
		font-size: 0.85em;
		color: $gray;
	}

	.dot {
		@include dot;
	}
}

.events--content {
}

.events--footer {
	text-align: center;
}

.events--elevator {
	display: inline-block;
	font-size: em(26);
	font-weight: $bold;
	text-decoration: none;
	letter-spacing: 0.04em;
	color: $turquoise;

	&:after {
		content: "^";
		display: inline-block;
		font-size: em(18, 26);
		padding-left: em(3, 18);
		transform: scaleX(1.5);
		transform-origin: 0 0;
	}
}

// Single event
// --------------------------
.event {
	width: 100%;
	max-width: em($eventsWidth);
	margin: 0 auto em(80);
}

.event--header {
	margin-bottom: em(14);
}

.event--date {
	display: block;
	font-size: em(19);
	letter-spacing: 0.08em;
	margin-bottom: em(7, 19);
}

.event--title {
	font-size: em(33);
	font-weight: $bold;
	text-transform: lowercase;
	text-decoration: underline;
	letter-spacing: 0.04em;
	line-height: 1;
	color: $red;
}

.event--content {
	font-size: em(19);
	line-height: 1.447;
	letter-spacing: 0;
	color: #4c4b4b;
	margin-bottom: em(100);
}

// figure
.event--map {
	a {
		text-decoration: none;
	}

	img {
		max-width: 100%;
		height: auto;
		display: block;
	}

	figcaption {
		padding: 0;
	}

	address {
		padding: em(12);
		font-style: normal;
		color: $grayDark;
		background-color: $grayLight;

		div {
			display: inline;
		}

		[itemprop="name"] {
			font-weight: $bold;
		}

		[itemprop="streetAddress"]::after {
			content: ", ";
		}

		[itemprop="addressCountry"] {
			display: none;
		}
	}
}

// a
.event--link {
	display: inline-block;
	font-size: em(26);
	font-weight: $bold;
	letter-spacing: 0.04em;
	text-decoration: none;
	color: $turquoise;
}

// Journal components
// --------------------------------------------------------
.journal {
	width: 100%;
	max-width: em($journalInner);
	margin: 0 auto;
}

.journal--header {
	text-align: center;
	margin-bottom: em(35);
}

.journal--title {
	@include svg-heading;

	.journal-msa & {
		img {
			width: 14em;
		}
	}
}

.journal--subheading {
	@include h2($blueDark);
	text-align: center;
	margin-bottom: $verticalSpace;
}

.journal--content {
}

// Post
// --------------------------
.post {
	margin-bottom: em(84);
	// Context for post--social
	position: relative;

	& + & {
		margin-top: ($verticalSpace * 2);

		&:before {
			content: "";
			display: block;
			border-top: 1px solid $blueLightAlt;
			margin: 0 em($horizontalSpace * 2) ($verticalSpace * 2);
		}
	}
	@include respond($vostok) {
		margin-bottom: em(34);

		& + & {
			margin-top: $verticalSpace;

			&:before {
				margin-bottom: $verticalSpace;
			}
		}
	}
}

.post--header {
	margin-bottom: em(15);

	p {
		margin-bottom: 0;
	}

	a {
		text-decoration: none;
	}
}

.post--kicker {
	@include h5($green);
}

.post--title {
	@include h1;
	text-transform: none;
	padding-top: em(4, 58);
	margin-bottom: em(12, 58);
}

.post--date,
.post--author {
	color: $green;
}

.post--share {
	border-radius: em(5);
	overflow: hidden;
	position: absolute;
	top: em(41);
	left: em(-$journalGutter);

	a {
		display: flex;
		justify-content: center;
		align-items: center;
		width: em(44);
		height: em(44);
		text-decoration: none;
		background-color: $blue;

		&:nth-child(1) {
			background-color: #3b5998;
		}

		&:nth-child(2) {
			background-color: #4099ff;
		}

		&:nth-child(3) {
			background-color: #cc9120;
		}
	}

	svg {
		fill: $white;
	}
	@include respond($journalWidth + ($horizontalSpace * 2)) {
		left: em(($journalGutter - $horizontalSpace) * -1);
	}
	@include respond($erie) {
		display: flex;
		border-radius: 0;
		margin-bottom: em(15);
		position: static;
	}
}

.post--content {
}

.post--more {
	font-size: em(23);
	font-weight: $bold;
	color: $green;
	text-transform: lowercase;
	text-decoration: none;
}

// Comments
// --------------------------
.post--comments {
	background-color: $blueLighterAlt;
	padding: 0 em($horizontalSpace * 2) 0 em($horizontalSpace);
	margin: em(52) em(-$horizontalSpace);
	box-shadow: 0 em(26) 0 0 $offWhite inset;
	@include respond($ontario) {
		padding: 0 em($horizontalSpaceNarrow);
		margin-right: em(-$horizontalSpaceNarrow);
		margin-left: em(-$horizontalSpaceNarrow);
	}
	@include respond($vostok) {
		box-shadow: 0 em(21) 0 0 $offWhite inset;
	}
}

.comments-title {
	@include h3($turquoise);
	margin-bottom: em(2, 35);
}

.comments {
	padding-bottom: em(71);
	// Context for comments--elevator
	position: relative;
}

// ul
.comments--list {
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 0;
}

// li
.comments--item {
	& + & {
		padding-top: em(23);
	}
	// Nested comment
	.comments--list {
		padding-top: em(15);
	}

	.comments--item {
		background-color: $white;
		padding: em(10) em($horizontalSpace) em(17);
		margin: 0 em($horizontalSpace * -2) em(11) 0;
		box-shadow: em($horizontalSpace) 0 0 0 $white;
	}
	@include respond($ontario) {
		.comments--item {
			padding-right: em($horizontalSpaceNarrow);
			padding-left: em($horizontalSpaceNarrow);
			margin-right: em(-$horizontalSpaceNarrow);
		}
	}
}

.comment {
	@include flush-paragraphs;
}

.comment--header {
}

.comment--title {
	display: inline-block;
	font-size: em(15);
	font-weight: $bold;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: $black;
}

.comment--meta {
	display: inline-block;
	font-size: em(18);
	color: $gray;
}

.comment--content {
	font-size: em(19);
}

.comment--actions {
}

.comment--link {
	display: inline-block;
	font-size: em(18);
	font-style: italic;
	text-decoration: none;
	color: $green;
	background: transparent;
	padding: 0;
	border: none;

	& + & {
		&:before {
			content: "/ ";
			font-style: normal;
		}
	}
}

// Inline reply form
.comments--reply-form {
	display: block;

	&[aria-hidden=true] {
		display: none;
	}
}

.comments--heading {
	@include h3($blueDark);
	padding-top: em(39, 35);
	margin-bottom: em(30, 35);
}

.comments--form {
	$space: em(48);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-right: $space;

	.comments--item & {
		background-color: $white;
		padding-top: em(10);
		padding-bottom: em(10);
		padding-left: em($horizontalSpace);
		margin: em(15) 0;

		.form-control {
			font-size: em(19);
		}

		textarea.form-control {
			height: auto;
		}
	}

	.comments--item .comments--item & {
		background-color: $blueLighterAlt;
	}

	.half {
		width: calc(50% - (#{$space}) / 2);
		margin-bottom: $verticalSpace;
	}

	.full {
		width: 100%;
	}

	.form-label {
		display: none;
	}

	.form-control {
		@include placeholder(#7d7878);
		display: block;
		width: 100%;
		font-size: em(21);
		letter-spacing: 0.04em;
		color: #7d7878;
		border: none;
		border-bottom: 1px solid #aeadae;
		background: transparent;
	}

	input.form-control {
		height: em(45);
		padding: 0;
	}

	textarea.form-control {
		height: em(118);
		padding: em(7) 0;
		resize: none;
	}
	// ul
	.form-errors {
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
		padding-top: em(6);
	}
	// li
	.form-error {
		@include h5($red);
	}

	.form-footer {
		width: 100%;
		text-align: center;
		padding-top: em(28);
		margin-left: ($space + em($horizontalSpace));
	}
	@include respond($ontario) {
		$space: em($horizontalSpaceNarrow);
		padding-right: 0;

		.comments--item & {
			padding-right: em($horizontalSpaceNarrow);
			padding-left: em($horizontalSpaceNarrow);
		}

		.half {
			width: calc(50% - (#{$space}) / 2);
		}
	}
	@include respond($vostok) {
		.half {
			width: 100%;
		}
	}
}

.comments--button,
.comments--elevator {
	display: inline-block;
	font-size: em(30);
	font-weight: $bold;
	letter-spacing: 0.04em;
	text-transform: lowercase;
}

.comments--button {
	color: $green;
	background: transparent;
	padding: 0;
	border: 0;

	.comments--item & {
		font-size: em(21);
	}
}

.comments--elevator {
	color: $blue;
	text-decoration: none;
	margin-left: em(($horizontalSpace / 2), 30);
	position: absolute;
	bottom: em(-19, 30);
	left: 50%;
	transform: translateX(-50%);
	@include respond($ontario) {
		font-size: em(24);
		left: auto;
		right: 0;
		transform: none;
	}
}

// Pagination
// --------------------------
.pagination {
	display: flex;
	justify-content: space-between;
	padding-top: em(48);
	margin: 0 em(-$horizontalSpace);
	@include respond($winnipeg) {
		margin: 0;
	}
	@include respond($vostok) {
		flex-direction: column;
		align-items: center;
	}
}

// a
.pagination--left,
.pagination--right {
	display: block;
	font-size: em(24);
	font-weight: $bold;
	line-height: 1;
	letter-spacing: 0.04em;
	text-transform: lowercase;
	color: $turquoise;
	// Context for :after
	position: relative;

	&:after {
		display: block;
		font-size: em(58, 24);
		line-height: 0.8;
		position: absolute;
		top: 0;
		bottom: 0;
		transform: scaleX(0.8);
	}

	small {
		display: block;
		font-size: em(16, 24);
		font-weight: $regular;
		text-transform: uppercase;
		color: $green;
		margin-bottom: em(3, 16);
	}
}

.pagination--left {
	padding-left: em(50, 24);

	&:after {
		content: "<";
		left: 0;
	}
	@include respond($winnipeg) {
		padding-right: em($horizontalSpaceNarrow);
	}
	@include respond($vostok) {
		text-align: center;
		padding: 0;
		margin-bottom: $verticalSpace;

		&:after {
			display: none;
		}
	}
}

.pagination--right {
	text-align: right;
	padding-right: em(50, 24);
	margin-left: auto;

	&:after {
		content: ">";
		right: 0;
	}
	@include respond($winnipeg) {
		padding-left: em($horizontalSpaceNarrow);
	}
	@include respond($vostok) {
		text-align: center;
		padding: 0;
		margin-left: 0;

		&:after {
			display: none;
		}
	}
}

.pagination-alt {
	@include respond($victoria) {
		display: none;
	}
}

// a
.pagination-alt--left,
.pagination-alt--right {
	display: block;
	font-size: em(75);
	font-weight: $bold;
	line-height: 1;
	color: #17f8e5;
	position: absolute;
	top: em(178, 75);
	transform: scaleX(0.8) scaleY(1.3);
}
$paginationOffset: 254px;

.pagination-alt--left {
	right: calc(100% + #{$paginationOffset});
}

.pagination-alt--right {
	left: calc(100% + #{$paginationOffset});
}

// Community
// --------------------------------------------------------
.account {
	width: 100%;
	max-width: em(600);
	margin: 0 auto;

	p {
		text-align: center;
	}
}

.account--heading {
	@include h2;
	text-align: center;
	margin-bottom: em(22, 40);
}

.account--form {
	padding: 0 em($horizontalSpace * 2);
	// ul
	.errors {
		list-style-type: none;
		padding-left: 0;

		li {
			@include h5;
			color: $red;
		}
	}
	@include respond($vostok) {
		padding: 0;
	}
}

.account--input {
	@include form-input;
}

.account--checkbox {
	@include form-checkbox;
}

.account--button {
	@include form-button;
}

.account--hint {
	font-size: em(16);
	letter-spacing: 0.04em;
	text-align: center;
}

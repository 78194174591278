// Base mixins
// -----------

// Media queries
// --------------------------------------------------------
// Respond to viewport size
// $size -> viewport size
// $operator -> 'min' or 'max'
// $aspect -> 'width' or 'height'
// --------------------------
@mixin respond($size, $operator: max, $aspect: width) {
    @media all and (#{$operator}-#{$aspect}: baseEm($size)) {
        @content;
    }
}

@mixin respond-range($upper, $lower) {
    @media all and (min-width: baseEm($lower)) and (max-width: baseEm($upper)) {
        @content;
    }
}

// Utilities
// --------------------------------------------------------
// Full bleed
// $direction accepts 'up', 'down' or 'none';
// NB using 'up' or 'down' causes the shadow to
// extend to the top or bottom of the viewport
// Only use 'none' on relatively wide elements,
// and set $width to the width of the element
// $side takes 'left', 'right' or 'both'
// --------------------------
@mixin full-bleed($color, $direction: none, $width: 400em, $side: both) {
    @if $color == "none" {
        box-shadow: none;
        background-color: transparent;
    } @else {
        @if $direction == none {
            @if $side == both {
                box-shadow: -$width 0 0 0 $color, $width 0 0 0 $color, (- $width * 2) 0 0 0 $color, ($width * 2) 0 0 0 $color;
            } @else if $side == left {
                box-shadow: -$width 0 0 0 $color, (- $width * 2) 0 0 0 $color;
            } @else if $side == right {
                box-shadow: $width 0 0 0 $color, ($width * 2) 0 0 0 $color;
            }
        }

        @if $direction == down {
            box-shadow: 0 $width 0 $width $color;
        }

        @if $direction == up {
            box-shadow: 0 (- $width) 0 $width $color;
        }
        background-color: $color;
    }
}

// Clearfix
// $mode accepts 'light' or 'heavy'
// 'light' is sufficient for clearing floats, while 'heavy' is required to prevent margin collapsing
// --------------------------
@mixin clearfix($mode: light) {
    @if $mode == heavy {
        &:before,
        &:after {
            content: " ";
            display: table;
        }

        &:after {
            clear: both;
        }
    } @else {
        &:after {
            content: " ";
            display: block;
            clear: both;
        }
    }
}

// Components
// --------------------------------------------------------
// Grid item
// --------------------------
@mixin grid-item($count, $space, $wrap: false) {
    $count-1: ($count - 1);
    width: calc((100% / #{$count}) - (#{$space} / #{$count}) * #{$count-1});

    @if $wrap != false {
        &:nth-child(#{$count}n+1):nth-last-child(-n+#{$count-1}) ~ & {
            margin-left: $space;
        }

        @for $i from 1 through ($count - 2) {
            $index: ($count - $i);

            &:nth-child(#{$count}n+#{$index}):last-child {
                margin-right: auto;
            }
        }
    }
}

// Properties that require browser prefixes
// --------------------------------------------------------
// User selection
// Prevents user from accidentally selecting text on click, helpful for labels
// --------------------------
@mixin no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

// Keyframes
// --------------------------
@mixin keyframes($name) {
    @-webkit-keyframes $name {
        @content;
    }

    @-moz-keyframes $name {
        @content;
    }

    @-ms-keyframes $name {
        @content;
    }

    @-o-keyframes $name {
        @content;
    }

    @keyframes $name {
        @content;
    }
}

// Animation
// --------------------------
@mixin animation($properties) {
    -webkit-animation: $properties;
    -moz-animation: $properties;
    -ms-animation: $properties;
    -o-animation: $properties;
    animation: $properties;
}

// Selection background
// --------------------------
@mixin selection($color) {
    ::-moz-selection {
        background: $color;
        text-shadow: none;
    }

    ::selection {
        background: $color;
        text-shadow: none;
    }
}

// Placeholder text
// --------------------------
@mixin placeholder($color, $style: normal) {
    &::-webkit-input-placeholder {
        color: $color;
        font-style: $style;
        @content;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    &:-moz-placeholder {
        color: $color;
        font-style: $style;
        @content;
        opacity: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    &::-moz-placeholder {
        color: $color;
        font-style: $style;
        @content;
        opacity: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    &:-ms-input-placeholder {
        color: $color;
        font-style: $style;
        @content;
    }
}

// Project specific mixins
// -----------------------

// Headings
// --------------------------
@mixin h1($color: #1b6d81) {
    font-size: em(58);
    font-weight: $bold;
    color: $color;
    text-transform: lowercase;
    line-height: 1.1;
    margin-bottom: em(14, 58);

    em {
        font-style: normal;
        color: $greenLight;
    }

    strong {
        color: #b8b8b8;
    }
    @include respond($vostok) {
        font-size: em(42);
    }
}

@mixin h2($color: $blue) {
    font-size: em(40);
    font-weight: $bold;
    color: $color;
    letter-spacing: 0.04em;
    text-transform: lowercase;
    line-height: 1.3;
    @include respond($vostok) {
        font-size: em(32);
    }
}

@mixin h3($color: $green) {
    font-size: em(35);
    font-weight: $bold;
    color: $color;
    text-transform: lowercase;
    line-height: 1.3;
    margin-bottom: em(6, 35);
    @include respond($vostok) {
        font-size: em(28);
    }
}

@mixin h5($color: #4d4e4e) {
    font-size: em(15);
    font-weight: $regular;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: $color;
}

@mixin svg-heading {
    display: inline-block;
    vertical-align: top;
    font-size: 1em;

    a {
        @include hover-opacity(undo);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
    }

    small {
        @include h5;
        display: block;
        margin-bottom: em(3, 14);
    }

    b {
        display: block;
        font-size: em(35);
        letter-spacing: 0.06em;
        text-transform: lowercase;
        color: $turquoise;
    }

    img {
        display: block;
        width: em(173);
        margin-bottom: em(-7);
    }
}

// Bleed
// $direction accepts 'right', 'left' or 'both'
// Set $wrapper to the width of the element's container
// Set $extra equal any padding on the bleed side of the element's container
// ('extra' space to make up when setting negative margins)
// Setting $padded as 'padded' adds gutter as padding to the bleed side of the element
// Set $container to the width of the element's container's container
// --------------------------
@mixin bleed($direction, $wrapper: $innerWidth, $extra: 0, $padded: unpadded, $container: $containerWidth) {
    // These are needed for use in calc()
    $wrapperEm: em($wrapper);
    $extraEm: em($extra);
    // Determine distance between element edge and edge of container
    $gutter: ((($container - $wrapper) / 2) + $extra);
    // Reset both right & left margins
    margin-right: 0;
    margin-left: 0;

    @if $direction == both {
        margin-right: em(-$gutter);
        margin-left: em(-$gutter);
    } @else {
        // Set negative margin
        margin-#{$direction}: em(-$gutter);
    }

    @if $padded == "padded" {
        // Set padding equal to gutter width
        padding-#{$direction}: em($gutter);
    }
    @include respond($container) {
        @if $direction == both {
            margin-right: calc((((100vw - #{$wrapperEm}) / 2) + #{$extraEm}) * -1);
            margin-left: calc((((100vw - #{$wrapperEm}) / 2) + #{$extraEm}) * -1);
        } @else {
            margin-#{$direction}: calc((((100vw - #{$wrapperEm}) / 2) + #{$extraEm}) * -1);
        }

        @if $padded == "padded" {
            padding-#{$direction}: calc(((100vw - #{$wrapperEm}) / 2) + #{$extraEm});
        }
    }
    // NB this is all project specific
    @if $direction == both {
        @include respond($wrapper + ($horizontalSpace * 2)) {
            margin-right: em(-$horizontalSpace);
            margin-left: em(-$horizontalSpace);
        }
        @include respond($malawi) {
            margin-right: em(-$horizontalSpaceNarrow);
            margin-left: em(-$horizontalSpaceNarrow);
        }
    } @else {
        @include respond($michigan) {
            // NB this accounts for the fact that page-content loses padding-right below $michigan (hence no $extra)
            @if $direction == right {
                margin-#{$direction}: em(-$horizontalSpace);
            } @else {
                margin-#{$direction}: em(($horizontalSpace + $extra) * -1);
            }

            @if $padded == "padded" {
                padding-#{$direction}: em($horizontalSpace + $extra);
            }
        }
        @include respond($malawi) {
            @if $direction == right {
                margin-#{$direction}: em(-$horizontalSpaceNarrow);
            } @else {
                margin-#{$direction}: em(($horizontalSpaceNarrow + $extra) * -1);
            }

            @if $padded == "padded" {
                padding-#{$direction}: em($horizontalSpaceNarrow + $extra);
            }
        }
        @include respond($winnipeg) {
            margin-right: em(-$horizontalSpaceNarrow);
            margin-left: em(-$horizontalSpaceNarrow);

            @if $padded == "padded" {
                padding-right: em($horizontalSpaceNarrow);
                padding-left: em($horizontalSpaceNarrow);
            }
        }
    }
}

// Offset wrapper
// --------------------------
@mixin offset-wrapper() {
    $offset: 20;
    $topOffset: 29;
    margin: 0 em(-$offset - 45) em($offset) 0;
    // Context for :after
    position: relative;

    &:after {
        content: "";
        border: 1px solid #dae0e1;
        position: absolute;
        top: em($topOffset);
        right: em(-$offset);
        bottom: em(-$offset);
        left: em($offset);
        z-index: -1;
    }
    @include respond($victoria) {
        $innerWidthEm: em($innerWidth);
        $offsetEm: em($offset);
        margin-right: calc((((100vw - #{$innerWidthEm}) / 2) - #{$offsetEm}) * -1);
    }
    @include respond($michigan) {
        margin-right: 0;
    }
    @include respond($malawi) {
        margin-right: em($horizontalSpaceNarrow);
    }
    @include respond($vostok) {
        &:after {
            top: em(24);
        }
    }
}

// Offset box
// Structure: [offset-box] > ul > li > a
// --------------------------
@mixin offset-box($undo: false, $background: $offWhite) {
    $space: 11;

    @if $undo == false {
        border: 1px solid $blueLightAlt;
        margin: em($space) 0 0 em($space);
        background-color: #f0f0ef;
        box-shadow: em(-$space) em(-$space) 0 0 $grayLight, em(-$space + 1) em(-$space + 1) 0 0 $background inset;

        a {
            @include hover-opacity;
        }
    } @else {
        border: none;
        margin: 0;
        background-color: transparent;
        box-shadow: none;
    }
}

// Nav item
// --------------------------
@mixin nav-item {
    width: em(325);
    padding: 0 0.5em;
    text-align: center;
    // Context for :after
    position: relative;

    &:nth-child(1) {
        .title {
            color: $red;
        }

        .line path {
            fill: $red;
        }
    }

    &:nth-child(2) {
        .title {
            color: $turquoise;
        }

        .line path {
            fill: $turquoise;
        }
    }

    &:nth-child(3) {
        .title {
            color: $blueLight;
        }

        .line path {
            fill: $blueLight;
        }
    }

    &.active {
        &:after {
            content: "";
        }

        .line {
            display: block;
            margin: 0 auto;
        }

        p {
            display: none;
        }
    }

    .title {
        font-size: em(35);
        font-weight: $bold;
        letter-spacing: 0.06em;
        text-transform: lowercase;
        color: $blueDark;
        margin-bottom: em(3, 35);
    }

    .line {
        display: none;

        path {
            fill: $blueDark;
        }
    }

    p {
        @include h5;
        line-height: 1.466;
        margin-bottom: 0;

        a {
            @include hover-opacity;
            color: $red;
            font-weight: bold;
            text-decoration: underline;

            li:nth-child(1) &,
            li:nth-child(3) & {
                color: $greenLight;
            }

            li:nth-child(2) & {
                color: $blueLight;
            }
        }
    }
    @include respond($vostok) {
        .title {
            font-size: em(28);
        }

        p {
            font-size: em(12);
        }
    }
}

// Button
// --------------------------
@mixin button($color: #3c84c5) {
    display: block;
    min-width: em(79, 17);
    font-size: em(17);
    font-weight: $bold;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    line-height: 1;
    color: $white;
    background-color: $color;
    padding: em(7, 17) em(9, 17);
    border: none;
    border-radius: em(8, 17);
    transition: $duration background-color $timing;

    &:hover {
        background-color: transparentize($color, 0.18);
    }
}

// Hover opacity
// $mode takes 'do' or 'undo'
// --------------------------
@mixin hover-opacity($mode: do) {
    @if $mode == 'undo' {
        &:hover {
            opacity: 1;
        }
    } @else {
        transition: opacity $duration $timing;

        &:hover {
            opacity: 0.84;
        }
    }
}

// Flush paragraphs
// --------------------------
@mixin flush-paragraphs {
    p {
        margin-bottom: 0;
    }

    p + p {
        margin-top: $verticalSpace;
    }
}

// Tripundra
// --------------------------
@mixin tripundra($color, $weight, $width, $height) {
    // Reset default <button> styles
    background: transparent;
    border: none;
    text-indent: -400em;
    // Apply the sacred ash
    display: block;
    width: $width;
    padding: (($height - $weight) / 2) 0;
    // Context
    position: relative;

    b,
    &:before,
    &:after {
        display: block;
        height: $weight;
        width: $width;
        border-radius: em(2);
        background-color: $color;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }
}

// Dot
// --------------------------
@mixin dot {
    background-color: $blue;
    display: inline-block;
    width: rem(12);
    height: rem(12);
    border: 1px solid transparentize($black, 0.9);
    border-radius: 50%;
}

// Form components
// --------------------------
@mixin form-input {
    margin-bottom: em(21);

    label {
        display: block;
        font-size: em(15);
        font-weight: $bold;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        color: $black;
        margin-bottom: em(4, 15);
    }

    input {
        display: block;
        width: 100%;
        height: em(51, 17);
        font-size: em(17);
        letter-spacing: 0.04em;
        color: #7d7878;
        background: $white;
        padding: 0 em(20, 17);
        border-radius: em(5);
        border: none;

        &:valid {
            box-shadow: 0 0 0 em(2, 17) #b2ecd2;
        }

        &,
        &:invalid {
            box-shadow: 0 0 0 em(2, 17) $grayLight;
        }
    }
}

@mixin form-checkbox {
    margin-bottom: em(21);
    // Context for label
    position: relative;

    label {
        font-size: em(15);
        text-transform: uppercase;
        letter-spacing: 0.04em;
        color: $black;
    }

    input {
        display: inline-block;
        margin-right: em(8);
    }
}

@mixin form-button {
    width: 100%;
    margin-bottom: em(21, 18);
    height: em(51, 18);
    font-size: em(18);
    font-weight: $bold;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $white;
    background: $green;
    padding: 0;
    border-radius: em(5, 18);
    border: none;

    &:hover,
    &:focus {
        background-color: transparentize($green, 0.18);
    }
}

// Fullcalendar components
// -----------------------

// Global
// --------------------------------------------------------
.fc {
	&.is-loading {
		&:after {
			$dimension: em(52);
			content: "";
			display: block;
			width: $dimension;
			height: $dimension;
			background-image: url('../images/spinner.svg');
			background-repeat: none;
			background-size: cover;
			position: fixed;
			top: calc(50% - (#{$dimension} / 2));
			left: calc(50% - (#{$dimension} / 2));
			opacity: 0.35;
			animation: spin 2s infinite linear;
		}
	}

	table {
		width: 100%;
		box-sizing: border-box;
		table-layout: fixed;
		border-collapse: collapse;
		border-spacing: 0;
		font-size: 1em;
	}

	thead {
		&,
		& tr,
		& th,
		& td {
			border: none;
		}
	}

	tbody {
		border-color: $gray;
	}

	th {
		padding: 0;
	}

	td {
		vertical-align: top;
		padding: 0;
		border: none;
	}

	.fc-row {
		border-width: 0;
	}

	.fc-divider {
		border-color: $gray;
		background: $offWhite;
	}

	.fc-row {
	}

	.fc-content {
		border-color: $gray;
	}

	.fc-axis {
		vertical-align: middle;
		padding: 0 4px;
		white-space: nowrap;
	}
}

.fc-row {
	position: relative;

	table {
		border-left: 0 hidden transparent;
		border-right: 0 hidden transparent;
		border-bottom: 0 hidden transparent;
	}

	.fc-bg {
		z-index: 1;
	}

	.fc-bgevent-skeleton {
		bottom: 0;
		z-index: 2;

		table {
			height: 100%;
		}

		td {
			border-color: transparent;
		}
	}

	.fc-highlight-skeleton {
		bottom: 0;
		z-index: 3;

		table {
			height: 100%;
		}

		td {
			border-color: transparent;
		}
	}

	.fc-content-skeleton {
		position: relative;
		z-index: 4;
		padding-bottom: 2px;

		td {
			background: none;
			border-color: transparent;
			border-bottom: 0;
		}

		tbody td {
			border-top: 0;
		}
	}

	.fc-helper-skeleton {
		z-index: 5;

		td {
			background: none;
			border-color: transparent;
			border-bottom: 0;
		}

		tbody td {
			border-top: 0;
		}
	}

	&.fc-rigid {
		overflow: hidden;

		.fc-content-skeleton {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}
	}

	&:first-child table {
		border-top: 0 hidden transparent;
	}
}

.fc-view {
	margin-bottom: em(35);
	transition: opacity $durationLong $timing;

	.is-loading & {
		opacity: 0.5;
	}
}

.fc-bg,
.fc-content-skeleton {
	table {
		border-collapse: separate;
		border-spacing: em(14) em(7);
	}
}

.fc-scroller {
	> .fc-day-grid {
		position: relative;
		width: 100%;
	}

	> .fc-time-grid {
		position: relative;
		width: 100%;
	}
	@include respond($winnipeg) {
		// This has to override inline styles set by events.js
		height: auto !important;
	}
}

.fc-day-grid .fc-row {
	z-index: 1;
}

// Header
// --------------------------------------------------------
.fc-toolbar {
	text-align: center;
	border-top: 1px solid #e5e5e5;
	// Context for fc-right, fc-prev-button, fc-next-button
	position: relative;

	.fc-left {
	}

	.fc-right {
		position: absolute;
		right: 0;
		bottom: 100%;
	}

	.fc-center {
		h2 {
			@include h2(#f99a61);
		}
	}

	.fc-clear {
		display: none;
	}
	@include respond($erie) {
		padding-bottom: em(46);

		.fc-center {
			h2 {
				font-size: em(35);
			}
		}
	}
}

.fc-prev-button,
.fc-next-button {
	@include h2(#cfd1d1);
	border: none;
	background: transparent;
	padding: 0;
	position: absolute;
	top: 0;
	@include respond($erie) {
		font-size: em(35);
		top: auto;
		bottom: 0;
	}
}

.fc-prev-button {
	left: 0;

	&:after {
		content: "\00ab";
	}
}

.fc-next-button {
	right: 0;

	&:after {
		content: "\00bb";
	}
}

.fc-month-button,
.fc-listMonth-button,
.fc-today-button {
	font-size: em(13);
	font-weight: $bold;
	text-transform: uppercase;
	color: $orange;
	letter-spacing: 0.08em;
	border: none;
	background: transparent;
	padding: 0;
	margin-bottom: em(15, 13);

	&.fc-state-active,
	&.fc-state-disabled {
		display: none;
	}
}

.fc-month-button,
.fc-listMonth-button {
	&:before {
		content: "";
		display: inline-block;
		vertical-align: top;
		width: em(20, 13);
		height: em(20, 13);
		background-image: url('../images/list.svg');
		background-repeat: no-repeat;
		background-size: cover;
		margin-right: em(15, 13);
	}
	@include respond($winnipeg) {
		display: none;
	}
}

.fc-today-button {
	position: absolute;
	bottom: 100%;
	left: 0;
}

// List view
// --------------------------------------------------------
.fc {
	.fc-list-table {
		table-layout: auto;
	}
}

.fc-list-view {
	width: 100%;
	max-width: em($eventsWidth);
	padding-top: em(39);
	border: none;
	margin-right: auto;
	margin-left: auto;
}

.fc-list-table {
	display: block;

	tbody,
	tr {
		display: block;
	}

	td {
		display: inline-block;
		border: none;
	}
}

// tr
.fc-list-heading {
	.fc-list-item + & {
		padding-top: em(17);
		border-top: 1px solid #887f7f;
		margin-top: em(27);
	}
}

.fc-list-heading-main {
	@include h2($turquoise);
	line-height: 1;
}

.fc-list-heading-alt {
	display: none;
}

// tr
.fc-list-item {
	td {
		font-size: em(19);
		letter-spacing: 0.04em;
		line-height: 1.447;
	}

	&.fc-has-url {
	}

	&:hover td {
	}
}

// td
.fc-list-item-time {
	letter-spacing: 0.08em;

	&:after {
		content: "\00a0\2013\00a0";
	}
}

// td
.fc-list-item-marker {
	&:after {
		content: "\00a0";
	}
}

.fc-event-dot {
	@include dot;
}

// td
.fc-list-item-title {
	a {
		text-decoration: underline;
		cursor: pointer;
	}
}

.fc-list-empty-wrap2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	background-color: $grayLight;
}

.fc-list-empty-wrap1 {
	text-align: center;
}

.fc-list-empty {
	font-size: em(19);
	text-transform: lowercase;
	color: $gray;
}

// Calendar view
// --------------------------------------------------------
.fc-month-view {
	padding-top: em(27);
	margin-right: em(-14);
	margin-left: em(-14);
}

.fc-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	table {
		height: 100%;
	}
}

.fc-bgevent-skeleton {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

.fc-day-header {
	font-size: em(17);
	font-weight: $regular;
	text-transform: uppercase;
	letter-spacing: 0.08em;
	line-height: 2.2;
	color: #9fa0a0;
}

// Day
// --------------------------
.fc .fc-day {
	border: 1px solid #cacaca;

	&.fc-today {
		background: $blueLighterAlt;
	}
}

.fc-other-month {
	opacity: 0.2;
}

// TODO figure out how to apply this to events as well
.fc-past {
	opacity: 0.4;
}

// Day content
// --------------------------
.fc-day-top {
	text-align: right;
}

.fc-day-number {
	font-family: $fontSerif;
	font-size: em(29);
	color: #808080;
	letter-spacing: 0.06em;
	line-height: 1.7;
	padding-right: em(14, 29);
}

.fc-event {
	display: block;
	// Override inline style
	border: 1px solid transparentize($black, 0.9) !important;
	border-radius: em(3);

	.fc-event-container[colspan] & {
		text-align: center;
	}
}

// a
.fc-day-grid-event {
	margin: 0 em(7);
	padding: em(6) em(7);
	cursor: pointer;

	.fc-content {
		display: flex;
		flex-direction: column;
		font-size: em(13);
		font-weight: $bold;
		text-transform: uppercase;
		text-decoration: none;
		line-height: 1.08;
		letter-spacing: 0.06em;
	}

	.fc-time {
		order: 1;
		margin-bottom: em(5, 13);
	}

	.fc-title {
		order: 2;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

// Functions
// ---------

@function em($px, $context: $emBase) {
	@return (($px / $context) * 1em);
}

@function rem($px) {
	@return (($px / 16) * 1rem);
}

@function baseEm($px, $context: 16) {
	@return (($px / $context) * 1em);
}
